import { createRouter, createWebHistory } from "vue-router";

import Imprint from "@/views/Imprint.vue"
import Contact from "@/views/Contact.vue"
import UnderConstruction from "@/views/UnderConstruction.vue"
import News from "@/views/News.vue";

const routes = [
    {
      path: '/:pathMatch(.*)*',
      redirect: 'news'
    },
    { 
      name: 'news', 
      path: '/news', 
      component: News 
    },
    { 
      name: 'about-us', 
      path: '/about-us', 
      component: UnderConstruction 
    },
    { 
      name: 'contact', 
      path: '/contact', 
      component: Contact 
    },
    { 
      name: 'imprint', 
      path: '/imprint', 
      component: Imprint 
    }
  ]

  const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
  });

export default router;