<template>
    <article style="display: flex; flex-direction: column; justify-content: center;">
        <h1 class="under_construction_title">Under Construction</h1>
        <div>
            <p>This page is under construction. Please come back soon!</p>
        </div>
    </article>
</template>

<script>
export default {
  name: 'Contact'
}
</script>

<style>
    .under_construction_title {
        margin: 0;
        padding: 0;
    }
</style>