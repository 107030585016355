<template>
  <div style="height: 100%; display: flex; align-items: center; justify-content: center;">
    <div class="contact_card_container">
        <img class="content_card_location_image" style="border-radius: 25px;" src="@/assets/location.jpg"/>
        <div class="contact_card_descriptions_container">
          <h1 class="text-4xl mb-7">Kontakt & Anfahrt</h1>
          <ContactAddress social_media google_maps />
        </div>
    </div>
  </div>
</template>

<script>
import ContactAddress from "@/components/ContactAddress.vue";

export default {
  name: 'Contact',
  components: {
    ContactAddress
  },
}
</script>

<style>
  .contact_card_container {
    width: 60%;
    display: flex; 
    gap: 2.5rem;
  }
  .content_card_location_image {
    width: 50%;
  }

  @media (max-width: 920px) {
      .contact_card_container{
        width: 75%; 
        gap: none;
        display: flex;
        justify-content: center;
        flex-wrap: wrap; 
      }
      .contact_card_descriptions_container {
        width: 100%;
      }
      .content_card_location_image {
        width: 100%;
      }
      .title {
        font-size: 30px;
      }
      .description { 
        font-size: 15px;
      }
  }
</style>