<template>
    <div class="h-full flex justify-center items-center">
       <div id="fb-root"></div>
      <div class="fb-page" data-href="https://www.facebook.com/flotins.eiswagerl/" data-tabs="timeline" data-width="500" data-height="100%" data-small-header="true" data-adapt-container-width="false" data-hide-cover="false" data-show-facepile="true"><blockquote cite="https://www.facebook.com/flotins.eiswagerl/" class="fb-xfbml-parse-ignore"><a href="https://www.facebook.com/flotins.eiswagerl/">FloTins Eiswagerl</a></blockquote></div>
    </div>
</template>

<script>
export default {
  name: 'News',
}
</script>