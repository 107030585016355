<template>
    <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.3/css/all.min.css"/>
    <nav>
        <img class="logo" style="height: 80px; margin-left: 40px; border-radius: 25px;" src="@/assets/logo.jpg" />
        <input type="checkbox" id="click" v-model="isMobileNavigationActivated">
        <label for="click" class="menu-btn">
        <i class="fas fa-bars" style="margin-right: 50px;"></i>
        </label>
        <ul style="margin-right: 50px;">
            <li><router-link :to="{ name: 'news' }" @click="close">Aktuelles</router-link></li>
            <li><router-link :to="{ name: 'contact' }" @click="close">Kontakt und Anfahrt </router-link></li>
            <li><router-link :to="{ name: 'imprint' }" @click="close">Impressum & Datenschutz</router-link></li>
        </ul>
    </nav>
    <div v-if="!isMobileNavigationActivated" style="height: calc(100vh - 110px); display: flex; flex-direction: column;">
      <div style="flex: 1; background-color: rgb(132 204 22);">
        <slot name="content"></slot>
      </div>
    </div>
</template>

<script>
export default {
  name: 'Navigation',
  data() {
    return {
      isMobileNavigationActivated: false
    }
  },
  methods: {
    close() {
      this.isMobileNavigationActivated = false;
    }
  }
}
</script>

<style>
nav{
  display: flex;
  height: 110px;
  width: 100%;
  background: rgb(22 163 74);
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}
nav ul{
  display: flex;
  flex-wrap: wrap;
  list-style: none;
}
nav ul li{
  margin: 0 5px;
}
nav ul li a{
  color: #f2f2f2;
  text-decoration: none;
  font-size: 22px;
  font-weight: 500;
  padding: 8px 15px;
  border-radius: 5px;
  letter-spacing: 1px;
  transition: all 0.3s ease;
}
nav ul li a.router-link-exact-active,
nav ul li a:hover{
  color: #111;
  background: #fff;
}
nav .menu-btn i{
  color: #fff;
  font-size: 22px;
  cursor: pointer;
  display: none;
}
input[type="checkbox"]{
  display: none;
}
@media (max-width: 1024px) {
  nav {
    height: 120px;
  }
  nav .menu-btn i{
    display: block;
  }
  #click:checked ~ .menu-btn i:before{
    content: "\f00d";
  }
  #click:checked .content{
    height: 0% !important;
  }
  nav ul{
    position: fixed;
    top: 120px;
    left: -100%;
    background: rgb(22 163 74);
    height: 100vh;
    width: 100%;
    text-align: center;
    display: block;
    transition: all 0.3s ease;
  }
  #click:checked ~ ul{
    left: 0;
  }
  nav ul li{
    width: 100%;
    margin: 40px 0;
  }
  nav ul li a{
    width: 100%;
    margin-left: -100%;
    font-size: 20px;
    transition: 0.6s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  }
  #click:checked ~ ul li a{
    margin-left: 0px;
  }
  nav ul li a.active,
  nav ul li a:hover {
    color: #111;
    background: #fff;
  }
}
</style>