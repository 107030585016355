<template>
  <div class="flex mb-4">
    <div class="mr-8">
      <p class="text-xl font-bold mb-1">Gasthaus "Zur Lacke"</p>
      <p class="text-lg">Karl Bauer</p>
      <p class="text-lg">Dekan-Seitz-Straße 8</p>
      <p class="text-lg">94356 Kirchroth</p>
    </div>
    <div v-if="google_maps" class="flex flex-row gap-8 mb-2">
      <div class="flex items-center">
        <i class="fas fa-map-marked-alt text-white cursor-pointer mb-1 hover:text-green-800 text-2xl" @click="navigateToGoogleMaps()"></i>
      </div>
    </div>
  </div>
  <div class="flex flex-col mb-2">
    <a class="text-lg underline" href="tel:+499428324">+49 (0) 9428 324</a>
    <a class="text-lg underline" href="tel:+497010208">+49 (0) 171 7010208</a>
    <a class="text-lg underline" href="mailto:gasthaus-zur-lacke@gmx.de">gasthaus-zur-lacke@gmx.de</a>
  </div>
  <div v-if="social_media" class="flex flex-row">
    <button class="px-4 py-2 text-white transition duration-100 ease-in-out bg-white border border-transparent rounded shadow-sm">
      <i class="fab fa-facebook text-2xl text-center text-blue-800 cursor-pointer" @click="navigateToFacebook()"></i>
    </button>
  </div>
</template>

<script>
export default {
  name: 'ContactAddress',
  props: {
    social_media: Boolean,
    google_maps: Boolean,
  },
  methods: {
    navigateToGoogleMaps() {
      window.open('https://goo.gl/maps/otGg2ZreCDgcYw6L9', '_blank').focus();
    },
    navigateToFacebook() {
      window.open('https://www.facebook.com/flotins.eiswagerl/', '_blank').focus();
    }
  }
}
</script>