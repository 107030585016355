<template>
  <div class="m-10">
    <h1 class="text-4xl mb-7">Impressum</h1>
    <p class="font-bold mb-6">Angaben gemäß § 5 TMG</p>
    <div class="flex lg:flex-row flex-col lg:gap-20">
      <div class="flex flex-col mb-6 lg:mb-0">
        <ContactAddress />
        <div class="mt-6">
          <p class="text-xl font-bold mb-2">Aufsichtsbehörde</p>
          <p class="text-lg underline">Landratsamt Straubing-Bogen</p>
          <p class="text-lg">Leutnerstr. 15</p>
          <p class="text-lg">94315 Straubing</p>
        </div>
        <div class="mt-6">
          <p class="text-xl font-bold mb-2">Vertreten durch</p>
          <p class="text-lg mb-2">Karl Bauer</p>
          <div class="flex flex-col">
            <a class="text-lg underline" href="tel:+499428324">+49 (0) 9428 324</a>
            <a class="text-lg underline" href="mailto:gasthaus-zur-lacke@gmx.de">gasthaus-zur-lacke@gmx.de</a>
          </div>
        </div>
      </div>
      <div class="flex flex-col">
        <div class="mb-6">
          <p class="text-xl font-bold mb-1">Umsatzsteuer-ID</p>
          <p class="text-lg">Umsatzsteuer-Identifikationsnummer gemäß § 27 a Umsatzsteuergesetz</p>
          <p class="text-lg font-bold">DE16220290364</p>
        </div>
        <div class="mb-6">
          <p class="text-xl font-bold mb-2">EU-Streitschlichtung</p>
          <p class="text-lg">Die Europäische Kommission stellt eine Plattform zur Online-Streitbeilegung (OS) bereit:</p>
          <a class="text-lg text-blue-800 underline" href="https://ec.europa.eu/consumers/odr/" target='_blank'>https://ec.europa.eu/consumers/odr/</a>
          <p class="text-lg mt-2">Unsere E-Mail-Adresse finden Sie oben im Impressum</p>
        </div>
        <div class="mb-8">
          <p class="text-xl font-bold mb-2">Verbraucher­streit­beilegung/Universal­schlichtungs­stelle</p>
          <p class="text-lg">Wir sind nicht bereit oder verpflichtet, an Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle teilzunehmen.</p>
        </div>
        <div>
          <p class="text-xl font-bold mb-4">Datenschutz</p>
          <button @click="showFilePreview('/files/AGB.pdf')" class="px-4 py-2 text-white transition duration-100 ease-in-out bg-green-500 border border-transparent rounded shadow-sm hover:bg-green-600 focus:border-green-500">Allgemeinen Geschäftsbedingungen</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ContactAddress from "@/components/ContactAddress.vue";


export default {
  name: 'Imprint',
  components: {
    ContactAddress
  },
  methods: {
    showFilePreview(href) {
      window.open(href, '_blank');
    }
  }
}
</script>