<template>
  <meta name="viewport" content="width=device-width, initial-scale=1.0">
  <Navigation>
    <template v-slot:content>
      <router-view />
    </template>
  </Navigation>
</template>

<script>
import Navigation from "@/components/Navigation.vue"

export default {
  name: 'App',
  components: {
    Navigation
  },
  created() {
    let tag = document.createElement("script");
    tag.setAttribute("src", 'https://connect.facebook.net/de_DE/sdk.js#xfbml=1&version=v12.0');
    document.head.appendChild(tag);
  }
}
</script>

<style>
@import url('https://fonts.googleapis.com/css?family=Poppins:400,500,600,700&display=swap');

html, body, #app {
  width: 100%;
  height: 100%;
}

*{
  margin: 0;
  padding: 0;
  font-family: 'Poppins', sans-serif;
}

h1, p, a, a:visited {
  color: rgb(255 255 255);
}

a:link
{
   color: rgb(255 255 255);
}

a:hover {
  color: rgb(22 101 52);
}

</style>
